import Vue from 'vue'
import Vuex from 'vuex'
import { BASE_URL } from '../utils'
import dayjs from 'dayjs'
import { formatNumber } from '../utils'

Vue.use(Vuex)

let isUpdate = false

export default new Vuex.Store({
  state: {
    tweetsDaoList: [],
    language: 'en',
    account: '12121',
  },
  mutations: {
    getTweetsDaoList(state, tweetsDaoList) {
      // console.log('get', isUpdate)
      if (isUpdate) {
        state.tweetsDaoList = [...tweetsDaoList]
      } else {
        state.tweetsDaoList = [...state.tweetsDaoList, ...tweetsDaoList]
      }
    },
    changeLang(state, lang) {
      state.language = lang
    },
    changeAccount(state, newAccount) {
      state.account = newAccount
    },
  },
  actions: {
    async getTweetsDaoList(context, playload) {
      // console.log('pageNum', playload.pageNum)
      // console.log('address', playload.address)
      const response = await fetch(
        `${BASE_URL}tweets?pageNum=${playload.pageNum}&address=${playload.address}`
      )
      const resData = await response.json()
      if (resData.result === 0) {
        resData.data.list.forEach((el) => {
          el.timestamp = dayjs(el.timestamp * 1000).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          el.twitter = 'https://twitter.com/' + el.user_username
          el.followers_count =
            formatNumber(el.followers_count) || el.followers_count
        })
        if (playload.pageNum === 1) {
          isUpdate = true
        } else {
          isUpdate = false
        }
        context.commit('getTweetsDaoList', resData.data.list)
      }
    },
  },
  getters: {
    // 通过地址或者名字筛选列表
    curTweetsDaoList: (state) => (str) => {
      return state.tweetsDaoList.filter((item, index) => {
        if (str === '') {
          return index < 10
        } else {
          return (
            item.wallet.toLowerCase().indexOf(str.toLowerCase()) > -1 ||
            item.user_name.toLowerCase().indexOf(str.toLowerCase()) > -1
          )
        }
      })
    },
    sliceTweetsDaoList: (state) => (index) => {
      return state.tweetsDaoList.slice(0, index * 10)
    },
    language: (state) => state.language,
    account: (state) => state.account,
  },
  modules: {},
})
