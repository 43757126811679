export const ContractMap = {
  bsc: '0x8D5AC44F019Fa9D233d9F0c0A42d4d113eDf0C09',
  // heco: "0xF94702856F9a8a46C3BE082d5086f5D65915BA11",
  eth: '0x8D5AC44F019Fa9D233d9F0c0A42d4d113eDf0C09',
  polygon: '0x8D5AC44F019Fa9D233d9F0c0A42d4d113eDf0C09',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const ChainIdMap = {
  // heco: 128,
  bsc: 56,
  eth: 1,
  polygon: 137,
}

export const ChainMap = {
  // 128: 'heco',
  56: 'bsc',
  1: 'eth',
  137: 'polygon',
}

export const BlockchainBrowserMap = {
  56: 'https://bscscan.com/tx/',
  1: 'https://polygonscan.com/tx/',
  137: 'https://etherscan.io/tx/',
}

export const NodeMap = {
  // heco: 'https://http-mainnet-node.huobichain.com',
  bsc: 'https://bsc-dataseed3.defibit.io',
  eth: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  polygon: 'https://polygon-rpc.com',
}
export const nativeCurrencyMap = {
  heco: {
    name: 'Huobi ECO Chain Native Token',
    symbol: 'HT',
    decimals: 18,
  },
  bsc: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  eth: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  polygon: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
  },
}

export const STEP = 20

export const formatURL = function (url) {
  if (!url) {
    return ''
  }
  if (url.indexOf('ipfs://') === 0) {
    url = url.replace(/ipfs:\/\//, 'https://infura-ipfs.io/ipfs/')
  }
  return url
}
