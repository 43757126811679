<template>
  <div class="header">
    <div class="header-navbar">
      <!-- <img src="../assets/common/five-degrees.png" class="left" /> -->
      <i class="text header-title">Web3 Social Bridge</i>
      <div class="right">
        <div class="text text-list" @click="isModal = true">
          {{ messages.shownList }}
        </div>
        <div class="text language-btn" @click="changeLang">
          {{ messages.language }}
        </div>
      </div>
    </div>
    <div class="header-content">
      <img src="../assets/common/main.png" class="left" />
      <div class="right">
        <div class="text title">{{ messages.title }}</div>
        <div class="text">
          {{ messages.desc1 }}
        </div>
        <div class="text">{{ messages.desc2 }}</div>
        <div class="text">{{ messages.desc3 }}</div>
        <div class="shown-in-the-list">
          <div class="text button" @click="isModal = true">
            {{ messages.shownList }}
          </div>
          <a
            :href="messages.tutorialUrl"
            target="_blank"
            class="text tutorial"
            >{{ messages.tutorial }}</a
          >
        </div>
      </div>
    </div>
    <Modal @close="close" v-if="isModal" :modal="messages.modal" />
  </div>
</template>

<script>
import Modal from './Modal.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Modal },
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModal: false,
    }
  },
  computed: { ...mapGetters(['language']) },
  methods: {
    close() {
      this.isModal = false
    },
    changeLang() {
      let lang = ''
      this.language === 'en' ? (lang = 'zh') : (lang = 'en')
      this.$store.commit('changeLang', lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  // height: 4.72rem;
  background: linear-gradient(
    90deg,
    rgba(0, 255, 252, 0.1),
    rgba(41, 134, 255, 0.1)
  );
  .header-navbar {
    width: 12rem;
    height: 0.72rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 1.35rem;
      height: 0.26rem;
    }
    .header-title {
      font-size: 0.32rem;
      font-weight: 700;
      color: #000;
    }
    .right {
      display: flex;
      align-items: center;
      .text-list {
        font-size: 0.16rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        cursor: pointer;
      }
      .language-btn {
        margin-left: 0.5rem;
        width: 1.06rem;
        height: 0.32rem;
        line-height: 0.32rem;
        font-size: 0.14rem;
        text-align: center;
        border: 0.01rem solid #979797;
        border-radius: 0.17rem;
        cursor: pointer;
      }
    }
  }
  .header-content {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3rem;
    .left {
      width: 5.06rem;
      height: 3.82rem;
    }
    .right {
      margin-top: 0.4rem;
      .title {
        font-size: 0.32rem;
        font-family: Inter, Inter-Regular;
        font-weight: 700;
      }
      :nth-child(2) {
        margin-top: 0.28rem;
      }
      :nth-child(3) {
        margin-top: 0.2rem;
      }
      :nth-child(4) {
        margin-top: 0.1rem;
      }
      .shown-in-the-list {
        margin-top: 0.4rem;
        width: 3.2rem;
        .button {
          width: 3.2rem;
          height: 0.58rem;
          line-height: 0.58rem;
          background: #2980fe;
          border-radius: 0.34rem;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
        .tutorial {
          display: block;
          margin-top: 0.2rem;
          font-size: 0.16rem;
          text-align: center;
          color: #2980fe;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
