<template>
  <div class="modal">
    <div class="modal-content">
      <img
        src="../assets/common/close.png"
        class="close"
        @click="$emit('close')"
      />
      <div class="collect-wallet" v-if="isConnect">
        <div class="text title">{{ modal.connectTitle }}</div>
        <div
          class="choose-btn"
          v-for="(wallet, index) in walletList"
          :key="index"
          :class="{ metamask: index === 1 }"
          @click="connectWallet"
        >
          <div class="left">
            <img :src="wallet.icon" />
            <span class="text">{{ wallet.text }}</span>
          </div>
          <img src="../assets/common/enter.png" class="enter" />
        </div>
      </div>
      <div class="collect-wallet post-proof" v-if="isSign">
        <div class="text title">{{ modal.signatureTitle }}</div>
        <div class="text proof-desc">
          {{ proofDesc }}
        </div>
        <input
          v-if="isPostProof"
          type="text"
          class="text"
          placeholder="https://twitter.com/Username/status/1490"
          v-model="tweet"
        />
        <div class="text error-message" v-if="isError">
          {{ modal.errorMessage }}
        </div>
        <div class="text proof-btn" @click="postProof">{{ proofBtnText }}</div>
      </div>
      <div class="collect-wallet confirm-success" v-show="isConfirm">
        <img src="../assets/common/success.png" class="success" />
        <div class="text confirm-title">{{ modal.successDesc }}</div>
        <div class="text confirm-desc">
          {{ modal.successTitle }}
        </div>
        <div class="synchronize">
          <input
            type="radio"
            :value="true"
            v-model="pick"
            @change="retainRecord"
            @click="controlSingel($event)"
          />
          <div class="text profile">{{ modal.profile }}</div>
          <div class="change-chain" ref="networkListBtn" @click="switchNetwork">
            <img :src="networkList[curIndex].icon" />
            <img src="../assets/common/enter.png" />
          </div>
          <div class="network-list" v-show="isNetworkList">
            <div
              class="network-item"
              v-for="(item, index) in networkList"
              :key="index"
              :class="{ active: curIndex === index }"
              @click="switchChain(item.chain, index)"
            >
              <img :src="item.icon" />
              <span class="text">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="text customize" @click="goFans3('edit')">
          {{ modal.customize }}
        </div>
        <div class="text proof-btn" @click="setProfile">{{ profileText }}</div>
      </div>
      <div
        class="collect-wallet confirm-success set-profile-success"
        v-show="isProcessing || isSuccess"
      >
        <div class="box" v-if="isSuccess"></div>
        <div class="text confirm-title">{{ successTitle }}</div>
        <div class="text confirm-desc" v-if="language === 'en'">
          {{ successDesc }}
          <span
            v-if="isProcessing"
            style="color: #2980fe; cursor: pointer"
            @click="checkStatus"
            >{{ modal.here }}</span
          >
        </div>
        <div class="text confirm-desc" v-else>
          您正在设置您的个人信息，可以在
          <span style="color: #2980fe; cursor: pointer" @click="checkStatus">
            {{ modal.here }}
          </span>
          查看进程
        </div>
        <div class="text proof-btn" @click="$emit('close')">
          {{ modal.close }}
        </div>
        <div class="text check-my-profile" @click="goFans3('address')">
          {{ modal.checkProfile }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from 'web3'
import { mapGetters } from 'vuex'
import { Base64 } from 'js-base64'

import ABI from '../constants/abi.json'
import { checkClinetModel, BASE_URL } from '../utils'
import {
  ChainIdMap,
  ChainMap,
  NodeMap,
  nativeCurrencyMap,
  ContractMap,
  BlockchainBrowserMap,
} from '../constants'
let signMessage = "To own your data, let's meet in web3. @5Degrees_ #5Degrees"
export default {
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      walletList: [
        {
          icon: require('../assets/common/dfox.png'),
          text: 'TokenPocket',
        },
        {
          icon: require('../assets/common/metamask.png'),
          text: 'Metamask',
        },
      ],
      networkList: [
        {
          icon: require('../assets/chain-icon/eth.png'),
          name: 'Ethereum Mainnet',
          chain: 'eth',
        },
        {
          icon: require('../assets/chain-icon/bsc.png'),
          name: 'Binance Smart Chain',
          chain: 'bsc',
        },
        {
          icon: require('../assets/chain-icon/polygon.png'),
          name: 'polygon',
          chain: 'polygon',
        },
      ],
      address: '',
      currentAddress: '',
      sig: '',
      isConnect: true,
      isPostProof: false,
      isSign: false,
      isConfirm: false,
      isError: false,
      tweet: '',
      pick: true,
      isNetworkList: false,
      chain: 'bsc',
      chainId: 56,
      curIndex: 1,
      isProcessing: false,
      isSuccess: false,
      transactionHash:
        '0xf6f6ea60364f91b36140bef3cd038bdc2859c8ed6ce08b6d9ba9b6a36650ea96',
    }
  },
  computed: {
    ...mapGetters(['language']),
    proofDesc() {
      if (this.isPostProof) {
        return this.modal.confirmDesc
      } else {
        return this.modal.signatureDesc
      }
    },
    proofBtnText() {
      if (this.isPostProof) {
        return this.modal.confirm
      } else {
        return this.modal.postProof
      }
    },
    profileText() {
      if (!this.pick) {
        return this.modal.close
      } else if (this.isNetworkList) {
        return this.modal.switchNetwork
      } else {
        return this.modal.confirm
      }
    },
    successTitle() {
      if (this.isProcessing) {
        return this.modal.processingTitle
      } else {
        return this.modal.setProfileTitle
      }
    },
    successDesc() {
      if (this.isProcessing) {
        return this.modal.processingDesc
      } else {
        return this.modal.setProfileDesc
      }
    },
  },
  created() {
    // 拿到已连接网站的钱包地址，判断是否已连接钱包
    if (window.ethereum) {
      this.address = window.ethereum.selectedAddress
      this.currentAddress = window.ethereum.selectedAddress.toLowerCase()
      this.currentChainId = Number(window.ethereum.chainId)
      window.ethereum.on('accountsChanged', (address) => {
        this.currentAddress = address[0].toLowerCase()
      })
      window.ethereum.on('chainChanged', (network) => {
        this.chainId = parseInt(network, 16)
        this.chain = ChainMap[this.chainId]
      })
    }
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener('click', this.onOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onOutside)
  },
  methods: {
    checkStatus() {
      console.log('go')
      window.open(BlockchainBrowserMap[this.chainId] + this.transactionHash)
    },
    goFans3(route) {
      window.open(`https://fans3.5degrees.io/#/${route}/${this.address}/bsc`)
    },
    onOutside(e) {
      const networkListBtn = this.$refs.networkListBtn
      if (!networkListBtn.contains(e.target)) {
        const contentWrap = document.querySelector('.network-list')
        if (contentWrap) {
          if (!contentWrap.contains(e.target)) {
            this.isNetworkList = false
          }
        }
      }
    },
    setProfile() {
      console.log('chain', this.chain)
      if (!this.pick) {
        this.$emit('close')
        return
      }
      if (this.address !== this.currentAddress) {
        alert('登录地址不对')
        return
      }
      if (this.chainId !== this.currentChainId) {
        this.switchChain(this.chain, 1)
      } else {
        let web3 = new Web3(window.ethereum)
        var instance = new web3.eth.Contract(ABI, ContractMap[this.chain])
        let extraInfo = ''

        extraInfo =
          'data:application/json;base64,' +
          Base64.encode(JSON.stringify(this.extraJson))
        instance.methods
          .setInfo(this.name, this.image, extraInfo)
          .send({ from: this.address })
          .on('transactionHash', (hash) => {
            // alert('Submitted!')
            // this.$emit('close')
            this.transactionHash = hash
            this.isProcessing = true
            this.isConfirm = false
          })
          .on('receipt', (receipt) => {
            this.isProcessing = false
            // alert('Success!')
            this.isSuccess = true
          })
          .on('error', (err) => {
            // alert(JSON.stringify(err))
          })
      }
    },
    switchNetwork() {
      this.isNetworkList = !this.isNetworkList
    },
    // 切换链
    async switchChain(chain, index) {
      console.log('switchChain', chain)
      this.chainId = ChainIdMap[chain]
      let chainId = '0x' + this.chainId.toString(16)

      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        })
        this.currentChainId = this.chainId
        this.curIndex = index
        this.chain = chain
        this.isNetworkList = false
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId,
                  chainName: chain.toUpperCase(),
                  rpcUrls: [NodeMap[chain]],
                  nativeCurrency: nativeCurrencyMap[chain],
                },
              ],
            })
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    controlSingel($event) {
      let _this = this
      window.setTimeout(() => {
        if (!this.changed) {
          $event.target.checked = false
          // 可以写些单选框没有选中的代码处理
          this.pick = false
        }
        _this.changed = false
      }, 0)
    },
    retainRecord() {
      // 可以写些单选框选中的代码处理
      this.changed = true
      this.pick = true
    },

    async connectWallet() {
      // if (
      //   navigator.userAgent.indexOf('TokenPocket') === -1 &&
      //   checkClinetModel() !== 'pc'
      // ) {
      //   alert('Please connect to Token Pocket to use')
      // }
      if (window.ethereum && this.address === null) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        this.address = accounts[0]
        this.currentAddress = accounts[0].toLowerCase()
        this.currentChainId = Number(window.ethereum.chainId)
        this.$store.commit('changeAccount', accounts[0])
      } else {
        // 调用签名
        // console.log('调用签名')
        this.walletSign()
      }
    },
    async walletSign() {
      try {
        const from = this.address
        const msg = `0x${Buffer.from(signMessage, 'utf8').toString('hex')}`
        this.sig = await window.ethereum.request({
          method: 'personal_sign',
          params: [msg, from, 'Example password'],
        })
        this.isConnect = false
        this.isSign = true
        this.fans3Url = window.encodeURIComponent(
          `https://fans3.5degrees.io/#/address/${this.address}/bsc`
        )
      } catch (err) {
        console.error(err)
      }
    },
    async postProof() {
      console.log('fans3Url', this.fans3Url)
      if (!this.isPostProof) {
        let twitterURL =
          'https%3A%2F%2Ftwitter.com%2F5Degrees_%2Fstatus%2F1493551653240274948'
        let url = `https://twitter.com/compose/tweet?url=${twitterURL}&text=`
        let signMessage1 =
          "To%20own%20your%20data%2C%20let's%20meet%20in%20web3.%20%405Degrees_%20%235Degrees"
        window.open(
          url +
            signMessage1 +
            '%20follow me in fan3👉' +
            this.fans3Url +
            '%20sig:' +
            this.sig
        )
        this.isPostProof = true
      } else {
        const url = 'https://openapi.5degrees.io/tweet'
        const data = {
          tweet: this.tweet,
          account: this.address,
        }
        // console.log('BASE_URL', BASE_URL)
        try {
          const response = await fetch(BASE_URL + 'tweet', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data),
          })
          const resData = await response.json()
          if (resData.result === 0) {
            console.log('请求成功')
            this.image = resData.data.user_profile_image_url
            this.name = resData.data.user_name
            this.extraJson = {}
            this.extraJson.description = resData.data.user_description
            this.extraJson.twitter =
              'https://twitter.com/' + resData.data.user_username
            this.isConfirm = true
            this.isSign = false
            const playload = {
              pageNum: 1,
              address: this.account,
            }
            this.$store.dispatch('getTweetsDaoList', playload)
          } else {
            this.isError = true
          }
        } catch (error) {
          this.isError = true
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  .proof-btn {
    margin-top: 0.25rem;
    width: 3.84rem;
    height: 0.54rem;
    background: #2980fe;
    border-radius: 0.27rem;
    font-size: 0.16rem;
    text-align: center;
    color: #ffffff;
    line-height: 0.54rem;
    cursor: pointer;
  }
  .modal-content {
    width: 5rem;
    background: #ffffff;
    border-radius: 0.12rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    .close {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      width: 0.2rem;
      height: 0.2rem;
      cursor: pointer;
    }
    .collect-wallet {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.41rem 0 0.53rem;
      .title {
        font-size: 0.24rem;
        text-align: center;
        font-weight: 600;
      }
      .choose-btn {
        margin-top: 0.37rem;
        width: 4.18rem;
        height: 0.78rem;
        background: #2980fe;
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;
        cursor: pointer;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 0.54rem;
            height: 0.54rem;
          }
          span {
            margin-left: 0.25rem;
            color: #ffffff;
          }
        }
        .enter {
          width: 0.16rem;
          height: 0.16rem;
        }
      }
      .metamask {
        background: #ffa857;
        margin-top: 0.1rem;
      }
    }
    .post-proof {
      .proof-desc {
        margin-top: 0.26rem;
        width: 3.66rem;
        font-size: 0.16rem;
        font-weight: 400;
        text-align: center;
        color: #999999;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #999999;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999999;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999999;
      }
      input {
        margin-top: 0.2rem;
        border: 0;
        width: 3.84rem;
        height: 0.54rem;
        border: 0.01rem solid #979797;
        border-radius: 0.12rem;
        font-size: 0.14rem;
        padding: 0 0.1rem;
      }
      .error-message {
        width: 3.7rem;
        text-align: center;
        margin-top: 0.1rem;
        color: red;
      }
    }
    .confirm-success {
      .success {
        width: 0.8rem;
        height: 0.8rem;
      }
      .confirm-title {
        margin-top: 0.18rem;
        font-size: 0.24rem;
        font-weight: 600;
        text-align: center;
      }
      .confirm-desc {
        margin-top: 0.2rem;
        width: 3.66rem;
        font-size: 0.16rem;
        text-align: center;
        color: #999999;
      }
      .synchronize {
        display: flex;
        align-items: center;
        margin: 0.3rem 0 0.1rem 0;
        position: relative;
        .network-list {
          position: absolute;
          top: -0.5rem;
          right: -2.4rem;
          z-index: 9999;
          background: #ffffff;
          border-radius: 0.12rem;
          box-shadow: 0 0.02rem 0.4rem 0 rgba(0, 0, 0, 0.2);
          .network-item {
            height: 0.52rem;
            // background: pink;
            // background: #ffffff;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            cursor: pointer;
            &:nth-child(1) {
              border-radius: 0.12rem 0.12rem 0 0;
            }
            &:last-child {
              border-radius: 0 0 0.12rem 0.12rem;
            }
            &.active {
              background: #2980fe;
              span {
                color: #ffffff;
              }
            }
            img {
              width: 0.32rem;
              height: 0.3rem;
            }
            span {
              margin-left: 0.12rem;
              white-space: nowrap;
              font-size: 0.14rem;
            }
          }
        }
        input {
          width: 0.16rem;
          height: 0.16rem;
        }
        .profile {
          margin: 0 0.1rem;
          font-size: 0.14rem;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #333333;
        }
        .change-chain {
          width: 0.46rem;
          height: 0.24rem;
          background: #2980fe;
          border-radius: 0.17rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.04rem;
          :nth-child(1) {
            width: 0.2rem;
            height: 0.2rem;
          }
          :nth-child(2) {
            width: 0.1rem;
            height: 0.1rem;
          }
        }
      }
      .customize {
        font-size: 0.14rem;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #2980fe;
        cursor: pointer;
      }
    }
    .set-profile-success {
      .box {
        width: 0.8rem;
        height: 0.8rem;
        background: #d8d8d8;
      }
      .check-my-profile {
        margin-top: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #2980fe;
        cursor: pointer;
      }
    }
  }
}
@media screen and(max-width:767px) {
  .modal {
    .proof-btn {
      margin-top: 0.25rem;
      width: 5.5rem;
      height: 1rem;
      line-height: 1rem;
      border-radius: 0.5rem;
      font-size: 0.3rem;
    }
    .modal-content {
      width: 6rem;
      border-radius: 0.35rem;
      .close {
        width: 0.3rem;
        height: 0.3rem;
      }
      .collect-wallet {
        .title {
          font-size: 0.32rem;
          font-weight: 700;
        }
        .choose-btn {
          width: 5rem;
          height: 1rem;
          border-radius: 0.22rem;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 0.68rem;
              height: 0.68rem;
            }
            span {
              margin-left: 0.25rem;
              font-size: 0.26rem;
              color: #ffffff;
            }
          }
        }
      }
      .post-proof {
        .proof-desc {
          width: 5.5rem;
          font-size: 0.26rem;
        }
        input {
          width: 5rem;
          height: 1rem;
          font-size: 0.24rem;
          border: 0.02rem solid #979797;
        }
        .error-message {
          width: 4.6rem;
          text-align: center;
          margin-top: 0.2rem;
          font-size: 0.26rem;
          color: red;
        }
        .proof-btn {
          width: 5rem;
          height: 1rem;
          line-height: 1rem;
          font-size: 0.26rem;
        }
      }
      .confirm-success {
        .confirm-title {
          font-size: 0.3rem;
        }
        .confirm-desc {
          width: 5.2rem;
          font-size: 0.26rem;
        }
        .synchronize {
          .network-list {
            top: -1.5rem;
            left: 50%;
            transform: translateX(-50%);
            right: 0;
            width: max-content;
            .network-item {
              height: 1rem;
              img {
                width: 0.65rem;
                height: 0.65rem;
              }
              span {
                margin-left: 0.12rem;
                white-space: nowrap;
                font-size: 0.3rem;
              }
            }
          }
          input {
            width: 0.3rem;
            height: 0.3rem;
          }
          .profile {
            font-size: 0.26rem;
          }
          .change-chain {
            width: 1rem;
            height: 0.48rem;
            border-radius: 0.3rem;
            :nth-child(1) {
              width: 0.4rem;
              height: 0.4rem;
            }
            :nth-child(2) {
              width: 0.2rem;
              height: 0.2rem;
            }
          }
        }
        .customize {
          font-size: 0.26rem;
        }
      }
      .set-profile-success {
        .box {
          width: 1.4rem;
          height: 1.4rem;
        }
        .check-my-profile {
          margin-top: 0.2rem;
          font-size: 0.26rem;
        }
      }
    }
  }
}
</style>
