//
//
export default {
  shownList: '加入名单',
  language: 'English',
  title: '我们正在Web 3.0 世界中发起一场社交革命',
  desc1:
    '5Degrees 是一个去中心化的社交协议，它可以帮助人们轻松探索Web3.0，旨在将Web 2.0用户与Web 3.0联系起来。',
  desc2: '在Web 3.0中，数据即资产，掌控自有数据，在Web3.0中遇见你的朋友们。',
  desc3: '现在，掌控自有资产',
  tutorial: '查看教程',
  tutorialUrl:
    'https://docs.5degrees.io/5degrees-protocol-of-web3/v/chinese/tutorial/How-to-sign-in-to-the-Web3-Social-Bridge',
  modal: {
    connectTitle: '连接钱包签名',
    signatureTitle: '验证您的签名',
    signatureDesc:
      '通过发布一条推特内容来验证您的账户。发布后需要返回该页面完成验证',
    postProof: '证明',
    confirmDesc: '粘贴您刚刚发布的个人推特网址',
    confirm: '确认',
    successTitle:
      '欢迎加入，现在您已成为支持5Degrees协议的一员！我们将在未来开拓一个更棒的社交网络',
    successDesc: '恭喜',
    errorMessage: '验证失败，请重新打开页面或者稍后重试',
    close: '关闭',
    switchNetwork: '切换网络',
    profile: '同步至个人profile',
    customize: '自定义',
    setProfileTitle: '成功',
    setProfileDesc: '至此您的个人信息设置完毕，欢迎加入5Degrees',
    checkProfile: '查看我的个人信息',
    processingTitle: '处理中',
    processingDesc: '您正在设置您的个人信息，可以在查看进程',
    here: '这里',
  },
  revolution: {
    title: '5Degrees 革命者名单',
    placeholder: '搜索地址或名字',
    showMore: '展示更多',
    resultNull: '没有搜索到匹配的数据，请换个关键词试试',
    toggleList: [
      {
        title: '最近',
      },
      {
        title: '发现',
      },
    ],
    recommendTitle: '还没有认识的朋友，可以从下面的推荐看看',
    recommendToggleList: [
      {
        title: '资产',
      },
      {
        title: 'Twitter热门',
      },
      {
        title: 'Fans3红人',
      },
    ],
  },
  footer: {
    title: '5Degrees',
    desc: '掌控自有数据，在Web3.0中遇见你的朋友们',
    resources: {
      title: '联系我们',
      list: [
        {
          text: 'Twitter',
          url: 'https://twitter.com/5Degrees_',
        },
        {
          text: 'GitHub',
          url: 'https://github.com/5DegreesProtocol',
        },
        {
          text: '品牌资源',
          url: 'https://www.5degrees.io/5degrees.zip',
        },
      ],
    },
    projects: {
      title: '项目',
      list: [
        {
          title: 'Fans3.0',
          url: 'https://fans3.5degrees.io/',
        },
        {
          title: 'Web3 社交桥梁',
          url: 'https://bridge.5degrees.io/',
        },
        {
          title: 'UniSwap+社交',
          url: 'https://uni.5degrees.io/#/follow',
        },
        {
          title: 'PancakeSwap+社交',
          url: 'https://cake.5degrees.io/#/follow',
        },
        {
          title: '即时提醒',
        },
        {
          title: 'Transit NFT+社交',
        },
        {
          title: '热门话题',
        },
        {
          title: '地址簿+社交',
        },
        {
          title: '加入我们',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSc0Qz1cRJm2CCqnlGe8t9ILSLfDcExuDhXkfu4YhH_cASAOfg/viewform',
        },
      ],
    },
  },
}
