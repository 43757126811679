<template>
  <div class="userinfo-mini-content">
    <div
      class="userinfo-mini"
      :class="{ active: index === 0 && isRecent }"
      v-for="(item, index) in miniList"
      :key="index"
    >
      <div class="top">
        <div class="left">
          <img
            :src="item.profile_image_url"
            class="avatar"
            :data-twitter="item.twitter"
            :onerror="defaultImg"
          />
          <div class="user-message">
            <div class="text username">{{ item.user_name }}</div>
            <div class="text othername">@{{ item.user_username }}</div>
            <div class="user-followers">
              <img
                src="../assets/common/twitter.png"
                :data-twitter="item.twitter"
              />
              <span class="text followers" :data-twitter="item.twitter"
                >{{ item.followers_count }} Followers</span
              >
            </div>
          </div>
        </div>
        <div class="right" :data-account="item.wallet">
          <img src="../assets/common/logo.png" :data-account="item.wallet" />
          <div class="text follow-btn" :data-account="item.wallet">
            {{
              item.wallet === account && account.toString() ? 'check' : 'Follow'
            }}
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="text address">{{ item.wallet }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    miniList: {
      type: Array,
    },
    defaultImg: {
      type: String,
    },
    isRecent: {
      type: Boolean,
    },
  },
  computed: { ...mapGetters(['account']) },
  methods: {
    gotoUserinfo(e) {
      // console.log('e', e.target.dataset)
      if (e.target.dataset.account) {
        // console.log('e', e.target.dataset.account)
        window.open(
          `https://fans3.5degrees.io/#/address/${e.target.dataset.account}/bsc`
        )
      }
      if (e.target.dataset.twitter) {
        console.log('e', e.target.dataset.twitter)
        window.open(e.target.dataset.twitter)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.userinfo-mini-content {
  .userinfo-mini {
    display: block;
    margin: 0 0.4rem;
    padding: 0.26rem 0.3rem;
    // height: 2rem;
    background: #ffffff;
    border-radius: 0.22rem;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.12);
    &:nth-of-type(n + 2) {
      margin-top: 0.1rem;
    }
    &.active {
      background: #f5f9ff;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .avatar {
          width: 0.98rem;
          height: 0.98rem;
          border-radius: 50%;
        }
        .user-message {
          margin-left: 0.38rem;
          width: 3.2rem;
          .username {
            font-size: 0.28rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .othername {
            margin-top: 0.05rem;
            font-size: 0.18rem;
            color: #999999;
          }
          .user-followers {
            margin-top: 0.16rem;
            display: flex;
            align-items: center;
            img {
              width: 0.22rem;
            }
            .followers {
              margin-left: 0.1rem;
              font-size: 0.18rem;
            }
          }
        }
      }
      .right {
        width: 1.2rem;
        height: 1rem;
        border: 0.01rem solid #2980fe;
        border-radius: 0.24rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 0.5rem;
        }
        .follow-btn {
          margin-top: 0.05rem;
          font-size: 0.18rem;
          text-align: center;
          color: #2980fe;
        }
      }
    }
    .line {
      height: 0.02rem;
      background: #eeeeee;
      margin: 0.25rem 0;
    }
    .address {
      font-size: 0.2rem;
      font-family: PingFangSC, PingFangSC-Regular;
      text-align: left;
      color: #999999;
    }
  }
}
</style>
