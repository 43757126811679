import { render, staticRenderFns } from "./UserinfoMini.vue?vue&type=template&id=71c6c506&scoped=true&"
import script from "./UserinfoMini.vue?vue&type=script&lang=js&"
export * from "./UserinfoMini.vue?vue&type=script&lang=js&"
import style0 from "./UserinfoMini.vue?vue&type=style&index=0&id=71c6c506&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c6c506",
  null
  
)

export default component.exports