<template>
  <div class="userinfo-content" @click="gotoUserinfo">
    <div
      class="userinfo"
      :class="{ active: index === 0 && isRecent }"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="left">
        <img
          :src="item.profile_image_url"
          class="avatar"
          :data-twitter="item.twitter"
          @mouseenter="mouseEnter(item, index)"
          @mouseleave="mouseLeave"
          :onerror="defaultImg"
        />
        <div class="user-message">
          <div class="username-wrap">
            <span class="text username">{{ item.user_name }}</span>
            <span class="text othername">@{{ item.user_username }}</span>
          </div>
          <div class="user-followers">
            <img
              src="../assets/common/twitter.png"
              :data-twitter="item.twitter"
            />
            <span class="text" :data-twitter="item.twitter"
              >{{ item.followers_count }} Followers</span
            >
          </div>
        </div>
        <div class="user-address">
          <div class="text address">
            {{ item.wallet }}
          </div>
          <div class="text time">{{ item.timestamp }}</div>
        </div>
        <div
          class="text userinfo-detail"
          @mouseenter="mouseEnter(item, index)"
          @mouseleave="mouseLeave"
          v-show="index === curIndex"
        >
          <div class="wrap">
            <div class="header">
              <img :src="item.profile_image_url" :onerror="defaultImg" />
              <div class="text follow-btn" :data-account="item.wallet">
                {{
                  item.wallet === account && account.toString()
                    ? 'Check'
                    : 'Follow'
                }}
              </div>
            </div>
            <div class="content">
              <div class="text description">{{ item.description }}</div>
              <div class="position-link">
                <div class="position" v-if="item.user_location">
                  <img src="../assets/common/position.png" />
                  <span class="text">{{ item.user_location }}</span>
                </div>
                <div class="position link" v-if="item.usd || item.usd === 0">
                  <!-- <img src="../assets/common/link.png" /> -->
                  <span class="text">$ {{ item.usd }}</span>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="twitter-degrees">
                <div class="twitter">
                  <img src="../assets/common/twitter.png" />
                  <span class="text" v-if="item.twitter_following_count">
                    {{ item.twitter_following_count }} Following
                  </span>
                  <span class="text">{{ item.followers_count }} Followers</span>
                </div>
                <div
                  class="twitter degrees"
                  v-if="item.five_degrees_following_count"
                >
                  <img src="../assets/common/logo.png" />
                  <span class="text">
                    {{ item.five_degrees_following_count }} Following
                  </span>
                  <span class="text">
                    {{ item.five_degrees_followers_count }} Followers
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right" :data-account="item.wallet">
        <div class="img-wrap" :data-account="item.wallet">
          <img src="../assets/common/logo.png" />
        </div>
        <div class="text follow-btn" :data-account="item.wallet">
          {{
            item.wallet === account && account.toString() ? 'Check' : 'Follow'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    list: {
      type: Array,
    },
    defaultImg: {
      type: String,
    },
    isRecent: {
      type: Boolean,
    },
  },
  data() {
    return {
      curIndex: '',
    }
  },
  computed: { ...mapGetters(['account']) },
  methods: {
    gotoUserinfo(e) {
      // console.log('e', e.target.dataset)
      if (e.target.dataset.account) {
        // console.log('e', e.target.dataset.account)
        window.open(
          `https://fans3.5degrees.io/#/address/${e.target.dataset.account}/bsc`
        )
      }
      if (e.target.dataset.twitter) {
        console.log('e', e.target.dataset.twitter)
        window.open(e.target.dataset.twitter)
      }
    },
    mouseEnter(item, index) {
      this.curIndex = index
    },
    mouseLeave() {
      this.curIndex = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.userinfo-content {
  .userinfo {
    width: 12rem;
    height: 1rem;
    background: #ffffff;
    border-radius: 0.12rem;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.05);
    &:nth-of-type(n + 2) {
      margin-top: 0.1rem;
    }
    &.active {
      background: #f5f9ff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.3rem;
    .left {
      display: flex;
      align-items: center;
      position: relative;

      .avatar {
        width: 0.44rem;
        height: 0.44rem;
        border-radius: 50%;
        cursor: pointer;
      }
      .user-message {
        width: 2.5rem;
        margin-left: 0.3rem;
        display: flex;
        flex-direction: column;
        .username-wrap {
          display: flex;
          .username {
            font-size: 0.16rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .othername {
            margin-left: 0.13rem;
            font-size: 0.14rem;
            color: #999999;
          }
        }
        .user-followers {
          margin-top: 0.12rem;
          display: flex;
          align-items: center;
          img {
            width: 0.16rem;
            height: 0.16rem;
            cursor: pointer;
          }
          span {
            margin-left: 0.1rem;
            font-size: 0.14rem;
            cursor: pointer;
          }
        }
      }
      .user-address {
        margin-left: 1rem;
        .address {
          font-size: 0.14rem;
        }
        .time {
          margin-top: 0.17rem;
          font-size: 0.12rem;
          color: #999999;
        }
      }
      .userinfo-detail {
        position: absolute;
        z-index: 8888;
        top: 0.2rem;
        left: 0.44rem;
        .wrap {
          margin-left: 0.27rem;
          width: 3rem;
          padding: 0.2rem;
          background: #ffffff;
          border-radius: 0.12rem;
          box-shadow: 0 0.02rem 0.4rem 0 rgba(0, 0, 0, 0.13);
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              width: 0.58rem;
              height: 0.58rem;
              border-radius: 50%;
            }
            .follow-btn {
              width: 0.94rem;
              height: 0.36rem;
              line-height: 0.36rem;
              text-align: center;
              background: #2980fe;
              border-radius: 0.24rem;
              font-size: 0.14rem;
              text-align: center;
              color: #ffffff;
              cursor: pointer;
            }
          }
          .content {
            margin: 0.1rem 0 0.2rem 0;
            .description {
              font-size: 0.12rem;
              color: #999999;
            }
            .position-link {
              margin-top: 0.1rem;
              display: flex;
              justify-content: space-between;
              .position {
                width: 50%;
                display: flex;
                align-items: center;
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                }
                span {
                  margin-left: 0.1rem;
                  font-size: 0.12rem;
                  color: #999999;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              .link {
                span {
                  color: #2980fe;
                }
              }
            }
          }
          .footer {
            .twitter-degrees {
              .twitter {
                display: flex;
                align-items: center;
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                }
                span {
                  flex: 1;
                  font-size: 0.14rem;
                }
                span:nth-child(2) {
                  margin-left: 0.08rem;
                }
              }
              .degrees {
                margin-top: 0.1rem;
              }
            }
          }
        }
      }
    }
    .right {
      display: flex;
      .img-wrap {
        width: 0.4rem;
        height: 0.4rem;
        background: #f5f9ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 0.32rem;
          height: 0.32rem;
          cursor: pointer;
        }
      }
      .follow-btn {
        margin-left: 0.2rem;
        width: 1.28rem;
        height: 0.36rem;
        line-height: 0.36rem;
        text-align: center;
        border: 0.01rem solid #2980fe;
        border-radius: 0.24rem;
        font-size: 0.16rem;
        color: #2980fe;
        cursor: pointer;
      }
    }
  }
}
</style>
