// import Store from 'store'

// 请求域名
// export const BASE_URL = 'https://tbtestapi1.mytokenpocket.vip/'
export const BASE_URL = 'https://openapi.5degrees.io/'
// http://192.168.110.117:3333/ranks?type=asset&pageNum

// 格式化数量，小于1,0009返回原数值，大于9999小于1000,000返回 1.xk，大于1,000,000返回 1.xM
export function formatNumber(number) {
  if (!Number.isInteger(number)) {
    return false
  }
  let numStr = ''
  if (number < 10000) {
    numStr = number.toString()
  } else if (number > 9999 && number < 1000000) {
    numStr = (number / 1000).toFixed(2).toString()
    numStr = numStr.slice(0, numStr.length - 1) + 'K'
    if (numStr.charAt(numStr.length - 2) === '0') {
      numStr = (number / 1000).toFixed() + 'K'
    }
  } else {
    numStr = (number / 1000000).toFixed(2).toString()
    numStr = numStr.slice(0, numStr.length - 1) + 'M'
    if (numStr.charAt(numStr.length - 2) === '0') {
      numStr = (number / 1000000).toFixed() + 'M'
    }
  }
  return numStr
}

export const RecommendMap = {
  0: 'asset',
  1: 'twitter',
  2: '5degrees',
}

// 判断安卓
export function isAndroid() {
  var u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    if (window.ShowFitness !== undefined) return true
  }
  return false
}
// 判断设备为 ios
export function isIos() {
  var u = navigator.userAgent
  if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
    return true
  }
  return false
}

export function checkClinetModel() {
  //获取请求头中的userAgent
  var u = navigator.userAgent
  //是否是android浏览器
  var isAndroid = u.indexOf('Android') > -1
  //是否是ios浏览器
  var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  var msg = ''
  if (isAndroid) {
    msg = 'android'
  } else if (isIos) {
    msg = 'ios'
  } else {
    msg = 'pc'
  }
  return msg
}

export function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

export function debounce(func, threshold, execAsap) {
  var timeout
  return function debounced() {
    var obj = this,
      args = arguments
    function delayed() {
      if (!execAsap) func.apply(obj, args)
      timeout = null
    }
    if (timeout) clearTimeout(timeout)
    else if (execAsap) func.apply(obj, args)
    timeout = setTimeout(delayed, threshold || 100)
  }
}

// 回到顶部
export function goTop() {
  document.documentElement.scrollTop = 0
  scrollTo(0, 0)
}
