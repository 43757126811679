<template>
  <div class="home">
    <Header :messages="messages" class="home-header" />
    <HeaderMini :messages="messages" class="home-headermini" />
    <RevolutionList :messages="messages" />
    <Footer :footer="messages.footer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from '../components/Header.vue'
import HeaderMini from '../components/HeaderMini.vue'
import RevolutionList from '../components/RevolutionList.vue'
import Footer from '../components/Footer.vue'
import English from '../lang/en'
import Chinese from '../lang//cn'
export default {
  name: 'Home',
  components: { Header, HeaderMini, RevolutionList, Footer },
  data() {
    return {
      english: English,
      chinses: Chinese,
      messages: {},
    }
  },
  created() {
    // 拿到已连接网站的钱包地址，判断是否已连接钱包
    if (window.ethereum) {
      this.account = window.ethereum.selectedAddress
      this.$store.commit('changeAccount', window.ethereum.selectedAddress)
      window.ethereum.on('accountsChanged', (account) => {
        this.$store.commit('changeAccount', account)
      })
    }
    let data = {
      pageNum: 1,
      address: this.account,
    }
    this.$store.dispatch('getTweetsDaoList', data)
  },
  computed: { ...mapGetters(['language']) },
  watch: {
    language: {
      immediate: true,
      handler() {
        if (this.language === 'en') {
          this.messages = this.english
        } else {
          this.messages = this.chinses
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and(max-width:767px) {
  .home {
    &-header {
      display: none;
    }
    &-headermini {
      display: block;
    }
  }
}
@media screen and(min-width:767px) {
  .home {
    &-header {
      display: block;
    }
    &-headermini {
      display: none;
    }
  }
}
</style>
