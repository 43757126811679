//
//
export default {
  shownList: 'Shown in the list',
  language: '中文',
  title: "WE BELIEVE IT'S TIME FOR A REVOLUTION.",
  desc1:
    '5Degrees, the Portal to the New, Open Internet, aims to bridge the Web 2.0 users to Web 3.0',
  desc2: 'Own your data, and meet your friends in Web3.0',
  desc3: 'Now, control your own asset',
  tutorial: 'View Tutorial',
  tutorialUrl:
    'https://docs.5degrees.io/5degrees-protocol-of-web3/tutorial/how-to-sign-in-to-the-web3-social-bridge',
  modal: {
    connectTitle: 'Connect wallet to sign',
    signatureTitle: 'Verify your signature',
    signatureDesc:
      'Tweet a message to prove that you control this address. Return back to this page aftewards to complete verification.',
    postProof: 'POST PROOF',
    confirmDesc: 'Paste the URL of the tweet you just posted',
    confirm: 'Confirm',
    successTitle:
      "Welcome to join in 5Degrees Now let's preparing for a better future of social web",
    successDesc: 'Congratulations',
    errorMessage:
      'Verification failed, please reopen the page or try again later',
    close: 'Close',
    switchNetwork: 'Switch network',
    profile: 'sync to personal profile',
    customize: 'customize',
    setProfileTitle: 'Success',
    setProfileDesc:
      'Now you have finished setting up your personal information, welcome to join in 5Degrees',
    checkProfile: 'check my profile',
    processingTitle: 'Processing',
    processingDesc:
      'You are setting up your information progress can be viewed',
    here: 'here',
  },
  revolution: {
    title: '5Degrees Revolutionaries List',
    placeholder: 'Search for address or name',
    showMore: 'show more',
    resultNull: 'No matching data is found, please try another keyword',
    toggleList: [
      {
        title: 'Recent',
      },
      {
        title: 'Find your friends',
      },
    ],
    recommendTitle:
      'There is no one you know here, maybe you can follow these people too',
    recommendToggleList: [
      {
        title: 'Assets',
      },
      {
        title: 'Twitter influencer',
      },
      {
        title: 'Fans3 influencer',
      },
    ],
  },
  footer: {
    title: '5Degrees',
    desc: 'Own your data, and meet your friends in Web3.0',
    resources: {
      title: 'Resources',
      list: [
        {
          text: 'Twitter',
          url: 'https://twitter.com/5Degrees_',
        },
        {
          text: 'GitHub',
          url: 'https://github.com/5DegreesProtocol',
        },
        {
          text: 'Brand Kit',
          url: 'https://www.5degrees.io/5degrees.zip',
        },
      ],
    },
    projects: {
      title: 'Projects',
      list: [
        {
          title: 'Fans3.0',
          url: 'https://fans3.5degrees.io/',
        },
        {
          title: 'Web3 Social Bridge',
          url: 'https://bridge.5degrees.io/',
        },
        {
          title: 'UniSwap+Socia',
          url: 'https://uni.5degrees.io/#/follow',
        },
        {
          title: 'PancakeSwap+Social',
          url: 'https://cake.5degrees.io/#/follow',
        },
        {
          title: 'Following Alert',
        },
        {
          title: 'Transit NFT+Social',
        },
        {
          title: 'Trending Topic',
        },
        {
          title: 'Address Book+Social',
        },
        {
          title: 'Join Us',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSc0Qz1cRJm2CCqnlGe8t9ILSLfDcExuDhXkfu4YhH_cASAOfg/viewform',
        },
      ],
    },
  },
}
