<template>
  <div class="revolution-list">
    <div class="header">
      <div class="text title">{{ messages.revolution.title }}</div>
      <div class="search-wrap">
        <img src="../assets/common/search.png" />
        <div class="line"></div>
        <input
          type="text"
          :placeholder="messages.revolution.placeholder"
          @keyup.enter="search"
          @blur="onBlur"
          v-model="value"
        />
      </div>
    </div>
    <div class="toggle">
      <div
        class="text toggle-item"
        v-for="(tItem, index) in messages.revolution.toggleList"
        :key="index"
        :class="{ active: index === curIndex }"
        @click="onToggle(index)"
      >
        {{ tItem.title }}
      </div>
    </div>
    <div class="reecnt-content" v-show="curIndex === 0">
      <Userinfo
        :list="filterTweetsDaoList"
        :defaultImg="defaultImg"
        :isRecent="true"
        v-if="isPc"
      />
      <UserinfoMini
        :miniList="filterTweetsDaoList"
        :defaultImg="defaultImg"
        :isRecent="true"
        v-else
      />
    </div>
    <div class="recommend-content" v-show="curIndex === 1">
      <div class="text title">
        {{ messages.revolution.recommendTitle }}
      </div>
      <div class="toggle recommend-toggle">
        <div
          class="text toggle-item recommend-toggle-item"
          v-for="(rtItem, index) in messages.revolution.recommendToggleList"
          :key="index"
          :class="{ active: index === curRecomIndex }"
          @click="onRecommend(index)"
        >
          {{ rtItem.title }}
        </div>
      </div>
      <div class="recommend-list">
        <Userinfo
          :list="filterRecommendList"
          :defaultImg="defaultImg"
          v-if="isPc"
        />
        <UserinfoMini
          :miniList="filterRecommendList"
          :defaultImg="defaultImg"
          v-else
        />
      </div>
    </div>
    <div class="text result-null" v-if="filterTweetsDaoList.length === 0">
      {{ messages.revolution.resultNull }}
    </div>
    <div class="show-more" v-if="isShowMore">
      <span class="text" @click="showMore">
        {{ messages.revolution.showMore }}
      </span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import { mapGetters } from 'vuex'
import { BASE_URL } from '../utils'
import { checkClinetModel, formatNumber, RecommendMap } from '../utils'
import Userinfo from './Userinfo.vue'
import UserinfoMini from './UserinfoMini.vue'
// import { mapGetters } from 'vuex'
export default {
  components: { Userinfo, UserinfoMini },
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: '',
      curTweetsDaoList: [],
      sliceTweetsDaoList: [],
      sliceRecommendList: [],
      recommendList: [],
      isSearch: false,
      curIndex: 0,
      curRecomIndex: 0,
      defaultImg:
        'this.src="' + require('../assets/common/default_profile.png') + '"',
    }
  },
  computed: {
    ...mapGetters(['account']),
    tweetsDaoList() {
      return this.$store.state.tweetsDaoList
    },
    filterTweetsDaoList() {
      if (
        this.curTweetsDaoList.length > 0 &&
        this.sliceTweetsDaoList.length === 0
      ) {
        return this.curTweetsDaoList
      }
      // 当搜索结果为空时
      else if (this.curTweetsDaoList.length === 0 && this.isSearch) {
        return this.curTweetsDaoList
      } else if (this.sliceTweetsDaoList.length > 0) {
        return this.sliceTweetsDaoList
      } else {
        return this.tweetsDaoList.slice(0, 10)
      }
    },
    filterRecommendList() {
      if (
        this.curTweetsDaoList.length > 0 &&
        this.sliceRecommendList.length === 0
      ) {
        return this.curTweetsDaoList
      }
      // 当搜索结果为空时
      else if (this.curTweetsDaoList.length === 0 && this.isSearch) {
        return this.curTweetsDaoList
      } else if (this.sliceRecommendList.length > 0) {
        return this.sliceRecommendList
      } else {
        return this.recommendList.slice(0, 10)
      }
    },
    isShowMore() {
      if (this.value === '') {
        if (this.isSearch) {
          return false
        }
        return true
      } else {
        return false
      }
    },
    isPc() {
      if (checkClinetModel() === 'pc') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.index = 2
    this.pageNum = 2
    this.recommendIndex = 1
    this.timeStart()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onToggle(index) {
      this.curIndex = index
      if (index !== 0) {
        this.pageNum = 1
        this.getSliceRecommendList(RecommendMap[this.curRecomIndex])
      } else {
        this.index = 2
        this.pageNum = 2
      }
    },
    onRecommend(index) {
      this.curRecomIndex = index
      this.recommendIndex = 1
      this.pageNum = 1
      this.recommendList = []
      this.getSliceRecommendList(RecommendMap[this.curRecomIndex])
    },
    async getRecommendList(val, pageNum) {
      const response = await fetch(
        `${BASE_URL}ranks?type=${val}&pageNum=${pageNum}`
      )
      const resData = await response.json()
      if (resData.result === 0) {
        resData.data.forEach((el) => {
          el.timestamp = dayjs(el.timestamp * 1000).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          el.twitter = 'https://twitter.com/' + el.user_username
          el.followers_count =
            formatNumber(el.twitter_followers_count) ||
            el.twitter_followers_count
        })
        this.recommendList = [...this.recommendList, ...resData.data]
        console.log('type', val)
        console.log('recommendList', this.recommendList)
      }
    },
    async getSliceRecommendList(val) {
      if (!this.recommendList.length) {
        await this.getRecommendList(val, this.pageNum)
      }
      if (
        this.recommendList.length &&
        this.recommendList.length === this.sliceRecommendList.length
      ) {
        this.pageNum++
        await this.getRecommendList(val, this.pageNum)
      }
      console.log('recommendIndex', this.recommendIndex)
      this.sliceRecommendList = this.recommendList.slice(
        0,
        this.recommendIndex * 10
      )
      this.recommendIndex++
    },
    onBlur() {
      if (this.value === '') {
        this.curTweetsDaoList = []
        this.isSearch = false
      }
    },
    async search() {
      this.sliceTweetsDaoList = []
      this.sliceRecommendList = []
      this.index = 2
      this.recommendIndex = 1

      const response = await fetch(`${BASE_URL}search?k=${this.value}`)
      const resData = await response.json()
      if (resData.result === 0) {
        this.isSearch = true
        resData.data.forEach((el) => {
          el.timestamp = dayjs(el.timestamp * 1000).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          el.twitter = 'https://twitter.com/' + el.user_username
          el.followers_count =
            formatNumber(el.followers_count) || el.followers_count
        })
        this.curTweetsDaoList = resData.data
        console.log('curTweetsDaoList', this.curTweetsDaoList)
      }
    },
    async showMore() {
      if (this.curIndex === 0) {
        this.getRecentList()
      } else {
        this.getSliceRecommendList(RecommendMap[this.curRecomIndex])
      }
    },
    async getRecentList() {
      if (this.sliceTweetsDaoList.length === this.tweetsDaoList.length) {
        const data = {
          pageNum: this.pageNum,
        }
        await this.$store.dispatch('getTweetsDaoList', data)
        this.pageNum++
        console.log('hidden')
      }
      console.log('more', this.$store.getters.sliceTweetsDaoList(this.index))
      this.sliceTweetsDaoList = this.$store.getters.sliceTweetsDaoList(
        this.index
      )
      this.index++
    },
    timeStart() {
      const data = {
        pageNum: 1,
        address: this.account,
      }
      this.timer = setInterval(() => {
        this.$store.dispatch('getTweetsDaoList', data)
        this.pageNum = 2
        this.index = 10
      }, 30000)
    },
  },
}
</script>

<style lang="scss" scoped>
.revolution-list {
  width: 12rem;
  margin: 0 auto;
  .header {
    height: 0.82rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 0.22rem;
      font-family: Inter, Inter-Regular;
      font-weight: 700;
    }
    .search-wrap {
      width: 3.09rem;
      height: 0.44rem;
      border: 0.01rem solid #d7d7d7;
      border-radius: 0.12rem;
      display: flex;
      align-items: center;
      img {
        margin-left: 0.13rem;
        width: 0.22rem;
        height: 0.22rem;
      }
      .line {
        margin: 0 0.15rem 0 0.08rem;
        width: 0.01rem;
        height: 0.2rem;
        background: #d7d7d7;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #d7d7d7;
        font-size: 0.14rem;
        font-family: Inter, Inter-Regular;
        font-weight: 400;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #d7d7d7;
        font-size: 0.14rem;
        font-family: Inter, Inter-Regular;
        font-weight: 400;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #d7d7d7;
        font-size: 0.14rem;
        font-family: Inter, Inter-Regular;
        font-weight: 400;
      }
      input {
        flex: 1;
        border: 0; // 去除未选中状态边框
        outline: none; // 去除选中状态边框
        background-color: rgba(0, 0, 0, 0); // 透明背景
      }
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    &-item {
      font-size: 0.16rem;
      color: #999999;
      cursor: pointer;
      &.active {
        color: #2980fe;
      }
    }
    :nth-child(n + 2) {
      margin-left: 0.3rem;
    }
  }
  .recommend-content {
    .title {
      font-size: 0.16rem;
      text-align: center;
    }
    .recommend-toggle {
      margin: 0.3rem 0 0.16rem 0;
      &-item {
        width: 1.5rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        background: #ffffff;
        border-radius: 0.12rem;
        box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.05);
        font-size: 0.14rem;
        font-family: PingFangSC, PingFangSC-Regular;
        border: 0.01rem solid #fff;
        &.active {
          border: 0.01rem solid #2761e7;
          color: #2761e7;
        }
      }
    }
  }
  .result-null {
    text-align: center;
  }
  .show-more {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
      color: #2980fe;
      text-decoration: underline #2980fe;
      cursor: pointer;
    }
  }
}
@media screen and(max-width:767px) {
  .revolution-list {
    width: 100vw;
    .header {
      margin-top: 0.4rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .title {
        width: 100%;
        text-align: center;
        font-size: 0.36rem;
      }
      .search-wrap {
        margin-top: 0.3rem;
        display: flex;
        width: 6rem;
        height: 0.7rem;
        img {
          width: 0.35rem;
          height: 0.35rem;
        }
        .line {
          height: 0.35rem;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers */
          font-size: 0.26rem;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          font-size: 0.26rem;
        }
        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          font-size: 0.26rem;
        }
        input {
          font-size: 0.3rem;
        }
      }
    }
    .toggle {
      margin: 0.5rem 0 0.5rem 0.4rem;
      &-item {
        font-size: 0.26rem;
      }
    }
    .recommend-content {
      .title {
        margin: 0 0.8rem;
        font-size: 0.26rem;
      }
      .recommend-toggle {
        margin: 0.5rem 0 0.5rem 0.4rem;
        &-item {
          width: 2.06rem;
          height: 0.6rem;
          line-height: 0.6rem;
          font-size: 0.24rem;
        }
      }
    }
    .show-more {
      span {
        font-size: 0.28rem;
      }
    }
  }
}
</style>
