<template>
  <div class="header">
    <div class="header-navbar">
      <!-- <img src="../assets/common/five-degrees.png" class="left" /> -->
      <i class="text header-title">Web3 Social Bridge</i>
      <div class="right">
        <div class="text text-list" @click="isModal = true">
          {{ messages.shownList }}
        </div>
        <div class="text language-btn" @click="changeLang">
          {{ messages.language }}
        </div>
      </div>
    </div>
    <div class="header-content">
      <div class="text title">{{ messages.title }}</div>
      <img src="../assets/common/main.png" class="left" />
      <div class="shown-in-the-list">
        <div class="text button" @click="isModal = true">
          {{ messages.shownList }}
        </div>
        <!-- <div class="text tutorial">{{ messages.tutorial }}</div> -->
        <a :href="messages.tutorialUrl" target="_blank" class="text tutorial">{{
          messages.tutorial
        }}</a>
      </div>
      <div class="text desc">
        {{ messages.desc1 }}
      </div>
      <div class="text desc">{{ messages.desc2 }}</div>
      <div class="text desc">{{ messages.desc3 }}</div>
    </div>
    <Modal @close="close" v-if="isModal" :modal="messages.modal" />
  </div>
</template>

<script>
import Modal from './Modal.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Modal },
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModal: false,
    }
  },
  computed: { ...mapGetters(['language']) },
  methods: {
    close() {
      this.isModal = false
    },
    changeLang() {
      let lang = ''
      this.language === 'en' ? (lang = 'zh') : (lang = 'en')
      this.$store.commit('changeLang', lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  // height: 13.75rem;
  padding-bottom: 1rem;
  background: linear-gradient(
    90deg,
    rgba(0, 255, 252, 0.1),
    rgba(41, 134, 255, 0.1)
  );
  .header-navbar {
    padding: 0 0.4rem;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 2.28rem;
    }
    .header-title {
      font-size: 0.32rem;
      font-weight: 700;
      color: #000;
    }
    .right {
      display: flex;
      align-items: center;
      .text-list {
        font-size: 0.2rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
      }
      .language-btn {
        margin-left: 0.2rem;
        width: 1.2rem;
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.22rem;
        text-align: center;
        border: 0.01rem solid #979797;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
  .header-content {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    .left {
      width: 6rem;
      // height: 3.82rem;
    }

    .title {
      margin-top: 0.6rem;
      width: 6rem;
      text-align: center;
      font-size: 0.54rem;
      font-family: Inter, Inter-Regular;
      font-weight: 700;
    }
    .desc {
      font-size: 0.24rem;
      text-align: center;
    }
    :nth-child(4) {
      width: 5.5rem;
      margin-top: 0.62rem;
    }
    :nth-child(5) {
      width: 6rem;
      margin: 0.3rem 0;
    }
    // :nth-child(6) {
    //   margin-top: 0.1rem;
    // }
    .shown-in-the-list {
      margin-top: 0.4rem;
      width: 5rem;
      .button {
        width: 5rem;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.28rem;
        background: #2980fe;
        border-radius: 0.5rem;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
      .tutorial {
        display: block;
        margin-top: 0.2rem;
        font-size: 0.28rem;
        text-align: center;
        color: #2980fe;
        cursor: pointer;
      }
    }
  }
}
</style>
